// Used for Links throughout FE app
// - `route:xxx` pattern is necessary, so that `route.id` is unique and can never be the same as an alias in any language
// - all routes are automatically mentined in sitemap.xml on build, unless route has `noIndex` prop set to true

const anchors = {
    business: {
        benefits: 'benefits',
        how_it_work: 'how-it-work',
        reference: 'reference',
    },
};

const queryParams = {
    join_us: {
        tier_name: 'tierName',
        tier_mode: 'tierMode',
    },
};

const paths = {
    index: '/route:index',

    about: '/route:about',
    faq: '/route:faq',
    faq_customer: '/route:faq-customer',
    contact: '/route:contact',
    privacy: '/route:privacy',
    sing_in: '/route:sing-in',
    register: '/route:register',
    license: '/route:license',

    czechia: '/route:CZ',
    spain: '/route:ES',
    italy: '/route:IT',
    slovakia: '/route:SK',

    facebook: '/route:facebook',
    twitter: '/route:twitter',
    instagram: '/route:instagram',
    linkedin: '/route:linkedin',

    download: '/route:download',

    czech_special: '/route:/CZ/czech-special',

    business_payment_link: '/route:payment-link',

    // ocellot / pos
    pos: '/route:pos',
    pos_sign_up: {
        cz: '/route:CZ/pos-sign-up',
    },

    // todo: probably legacy, we can remove it
    business_reason_to_pay: {
        cz: '/route:CZ/#ReasonsToPay',
        es: '/route:ES/#ReasonsToPay',
        it: '/route:IT/#ReasonsToPay',
        sk: '/route:SK/#ReasonsToPay',
    },

    // todo: probably legacy, we can remove it
    business_products: {
        cz: '/route:CZ/business#Products',
        es: '/route:ES/business#Products',
        it: '/route:IT/business#Products',
        sk: '/route:SK/business#Products',
    },

    business_reference: {
        cz: `/route:CZ/business_reference`,
        es: `/route:ES/business_reference`,
        it: `/route:IT/business_reference`,
        sk: `/route:SK/business_reference`,
    },



    personal: {
        cz: '/route:CZ/personal',
        es: '/route:ES/personal',
        it: '/route:IT/personal',
        sk: '/route:SK/personal',
    },

    business_benefits: {
        cz: `/route:CZ/business_benefits`,
        es: `/route:ES/business_benefits`,
        it: `/route:IT/business_benefits`,
        sk: `/route:SK/business_benefits`,
    },

    business_how_it_work: {
        cz: '/route:CZ/business_how_it_work',
        es: '/route:ES/business_how_it_work',
        it: '/route:IT/business_how_it_work',
        sk: '/route:SK/business_how_it_work',
    },

    personal_reason_to_pay: {
        cz: '/route:CZ/personal#ReasonsToPay',
        es: '/route:ES/personal#ReasonsToPay',
        it: '/route:IT/personal#ReasonsToPay',
        sk: '/route:SK/personal#ReasonsToPay',
    },
    personal_how_to_pay: {
        cz: '/route:CZ/personal#HowToPay',
        es: '/route:ES/personal#HowToPay',
        it: '/route:IT/personal#HowToPay',
        sk: '/route:SK/personal#HowToPay',
    },
    personal_reference: {
        cz: '/route:CZ/personal#Reference',
        es: '/route:ES/personal#Reference',
        it: '/route:IT/personal#Reference',
        sk: '/route:SK/personal#Reference',
    },

    pricing: {
        cz: '/route:CZ/pricing',
        es: '/route:ES/pricing',
        it: '/route:IT/pricing',
        sk: '/route:SK/pricing',
    },

    join_us: {
        cz: '/route:CZ/join-us',
        es: '/route:ES/join-us',
        it: '/route:IT/join-us',
        sk: '/route:SK/join-us',
    },
    blog: {
        cz: '/route:CZ/blog',
        es: '/route:ES/blog',
        it: '/route:IT/blog',
        sk: '/route:SK/blog',
    },
    terms: {
        cz: '/route:CZ/terms',
        es: '/route:ES/terms',
        it: '/route:IT/terms',
        sk: '/route:SK/terms',
        pe: '/route:PE/terms',
    },
    terms_archive: {
        cz: '/route:CZ/terms/archive',
        es: '/route:ES/terms/archive',
        it: '/route:IT/terms/archive',
        sk: '/route:SK/terms/archive',
        pe: '/route:PE/terms/archive',
    },
    terms_users: {
        cz: '/route:CZ/users',
        es: '/route:ES/users',
        it: '/route:IT/users',
        sk: '/route:SK/users',
        pe: '/route:PE/users',
    },
    terms_transformation: {
        cz: '/route:CZ/transformation',
        es: '/route:CZ/transformation',
        it: '/route:CZ/transformation',
        sk: '/route:CZ/transformation',
        pe: '/route:CZ/transformation',
    },
    terms_merchants: {
        cz: '/route:CZ/merchants',
        es: '/route:ES/merchants',
        it: '/route:IT/merchants',
        sk: '/route:SK/merchants',
        pe: '/route:PE/merchants',
    },
    terms_gdpr: {
        cz: '/route:CZ/gdpr',
        es: '/route:ES/gdpr',
        it: '/route:IT/gdpr',
        sk: '/route:SK/gdpr',
        pe: '/route:PE/gdpr',
    },
    terms_cookies: {
        cz: '/route:CZ/cookies',
        es: '/route:ES/cookies',
        it: '/route:IT/cookies',
        sk: '/route:SK/cookies',
        pe: '/route:PE/cookies',
    },
    terms_cashback: '/route:terms_cashback',
    externalPayApp: '/route:ext-pay-app',
    externalPayAppSavedCards: '/route:pay-ext-pay-app-saved-cards',
    externalBusinessWebApp: '/route:ext-business-web-app',
};

// Used at server
const routes = [
    {
        id: paths.index,
        template: '/',
        aliases: {
            cs: '/CZ',
            sk: '/SK',
            en: '/ES',
            es: '/IT',
            it: '/SK',
            fr: '/FR',
            de: '/DE',
            pt: '/PT',
        },
    },
    {
        id: paths.spain,
        template: '/ES',
        aliases: {
            cs: '/ES',
            sk: '/ES',
            en: '/ES',
            es: '/ES',
            it: '/ES',
            fr: '/ES',
            de: '/ES',
            pt: '/ES',
        },
    },
    {
        id: paths.czechia,
        template: '/CZ',
        aliases: {
            cs: '/CZ',
            sk: '/CZ',
            en: '/CZ',
            es: '/CZ',
            it: '/CZ',
            fr: '/CZ',
            de: '/CZ',
            pt: '/CZ',
        },
    },
    {
        id: paths.italy,
        template: '/IT',
        aliases: {
            cs: '/IT',
            sk: '/IT',
            en: '/IT',
            es: '/IT',
            it: '/IT',
            fr: '/IT',
            de: '/IT',
            pt: '/IT',
        },
    },
    {
        id: paths.slovakia,
        template: '/SK',
        aliases: {
            cs: '/SK',
            sk: '/SK',
            en: '/SK',
            es: '/SK',
            it: '/SK',
            fr: '/SK',
            de: '/SK',
            pt: '/SK',
        },
    },

    {
        id: paths.download,
        template: '/CZ/stahnout',
        aliases: {
            cs: '/CZ/stahnout',
            sk: '/CZ/stiahnut',
            en: '/CZ/download',
            es: '/CZ/download',
            it: '/CZ/download',
            fr: '/CZ/telecharger',
            de: '/CZ/herunterladen',
            pt: '/CZ/descarregar',
        },
    },

    {
        id: paths.czech_special,
        template: '/CZ/czech-special',
        aliases: {
            cs: '/CZ/cesky-special',
            sk: '/CZ/czech-special',
            en: '/CZ/czech-special',
            es: '/CZ/checo-especial',
            it: '/CZ/cechia-speciale',
            fr: '/CZ/spécial-tcheque',
            de: '/CZ/tschechisch-spezial',
            pt: '/CZ/czech-special',
        },
    },
    {
        id: paths.pricing.es,
        template: '/ES/pricing',
        aliases: {
            cs: '/ES/cenik',
            sk: '/ES/cenik',
            en: '/ES/pricing',
            es: '/ES/precios',
            it: '/ES/prezzi',
            fr: '/ES/prix',
            de: '/ES/preise',
            pt: '/ES/precos',
        },
    },

    {
        id: paths.pricing.it,
        template: '/IT/pricing',
        aliases: {
            cs: '/IT/cenik',
            sk: '/IT/cenik',
            en: '/IT/pricing',
            es: '/IT/precios',
            it: '/IT/prezzi',
            fr: '/IT/prix',
            de: '/IT/preise',
            pt: '/IT/precos',
        },
    },
    {
        id: paths.pricing.cz,
        template: '/CZ/pricing',
        aliases: {
            cs: '/CZ/cenik',
            sk: '/CZ/cenik',
            en: '/CZ/pricing',
            es: '/CZ/precios',
            it: '/CZ/prezzi',
            fr: '/CZ/prix',
            de: '/CZ/preise',
            pt: '/CZ/precos',
        },
    },
    {
        id: paths.pricing.sk,
        template: '/SK/pricing',
        aliases: {
            cs: '/SK/cenik',
            sk: '/SK/cenik',
            en: '/SK/pricing',
            es: '/SK/precios',
            it: '/SK/prezzi',
            fr: '/SK/prix',
            de: '/SK/preise',
            pt: '/SK/precos',
        },
    },

    {
        id: paths.join_us.es,
        template: '/ES/join-us',
        aliases: {
            cs: '/ES/pridejte-se',
            sk: '/ES/pridejte-se',
            en: '/ES/join-us',
            es: '/ES/unete',
            it: '/ES/unisciti-a-noi',
            fr: '/ES/rejoindre',
            de: '/ES/mitmachen',
            pt: '/ES/junte-se',
        },
    },

    {
        id: paths.join_us.it,
        template: '/IT/join-us',
        aliases: {
            cs: '/IT/pridejte-se',
            sk: '/IT/pridejte-se',
            en: '/IT/join-us',
            es: '/IT/unete',
            it: '/IT/unisciti-a-noi',
            fr: '/IT/rejoindre',
            de: '/IT/mitmachen',
            pt: '/IT/junte-se',
        },
    },
    {
        id: paths.join_us.cz,
        template: '/CZ/join-us',
        aliases: {
            cs: '/CZ/pridejte-se',
            sk: '/CZ/pridejte-se',
            en: '/CZ/join-us',
            es: '/CZ/unete',
            it: '/CZ/unisciti-a-noi',
            fr: '/CZ/rejoindre',
            de: '/CZ/mitmachen',
            pt: '/CZ/junte-se',
        },
    },

    {
        id: paths.join_us.sk,
        template: '/SK/join-us',
        aliases: {
            cs: '/SK/pridejte-se',
            sk: '/SK/pridejte-se',
            en: '/SK/join-us',
            es: '/SK/unete',
            it: '/SK/unisciti-a-noi',
            fr: '/SK/rejoindre',
            de: '/SK/mitmachen',
            pt: '/SK/junte-se',
        },
    },

    {
        id: paths.business_reason_to_pay.es,
        template: '/ES/business',
        aliases: {
            cs: '/ES/obchodnik#ReasonsToPay',
            sk: '/ES/obchodnik#ReasonsToPay',
            en: '/ES/business#ReasonsToPay',
            es: '/ES/negocios#ReasonsToPay',
            it: '/ES/business#ReasonsToPay',
            fr: '/ES/entreprise#ReasonsToPay',
            de: '/ES/unternehmen#ReasonsToPay',
            pt: '/ES/negocio#ReasonsToPay',
        },
    },

    {
        id: paths.business_reason_to_pay.it,
        template: '/IT/business',
        aliases: {
            cs: '/IT/obchodnik#ReasonsToPay',
            sk: '/IT/obchodnik#ReasonsToPay',
            en: '/IT/business#ReasonsToPay',
            es: '/IT/negocios#ReasonsToPay',
            it: '/IT/business#ReasonsToPay',
            fr: '/IT/entreprise#ReasonsToPay',
            de: '/IT/unternehmen#ReasonsToPay',
            pt: '/IT/negocio#ReasonsToPay',
        },
    },
    {
        id: paths.business_reason_to_pay.cz,
        template: '/CZ/business',
        aliases: {
            cs: '/CZ/obchodnik#ReasonsToPay',
            sk: '/CZ/obchodnik#ReasonsToPay',
            en: '/CZ/business#ReasonsToPay',
            es: '/CZ/negocios#ReasonsToPay',
            it: '/CZ/business#ReasonsToPay',
            fr: '/CZ/entreprise#ReasonsToPay',
            de: '/CZ/unternehmen#ReasonsToPay',
            pt: '/CZ/negocio#ReasonsToPay',
        },
    },

    {
        id: paths.business_reason_to_pay.sk,
        template: '/SK/business',
        aliases: {
            cs: '/SK/obchodnik#ReasonsToPay',
            sk: '/SK/obchodnik#ReasonsToPay',
            en: '/SK/business#ReasonsToPay',
            es: '/SK/negocios#ReasonsToPay',
            it: '/SK/business#ReasonsToPay',
            fr: '/SK/entreprise#ReasonsToPay',
            de: '/SK/unternehmen#ReasonsToPay',
            pt: '/SK/negocio#ReasonsToPay',
        },
    },

    {
        id: paths.business_products.es,
        template: '/ES/business',
        aliases: {
            cs: '/ES/obchodnik#Products',
            sk: '/ES/obchodnik#Products',
            en: '/ES/business#Products',
            es: '/ES/negocios#Products',
            it: '/ES/business#Products',
            fr: '/ES/entreprise#Products',
            de: '/ES/unternehmen#Products',
            pt: '/ES/negocio#Products',
        },
    },

    {
        id: paths.business_products.it,
        template: '/IT/business',
        aliases: {
            cs: '/IT/obchodnik#Products',
            sk: '/IT/obchodnik#Products',
            en: '/IT/business#Products',
            es: '/IT/negocios#Products',
            it: '/IT/business#Products',
            fr: '/IT/entreprise#Products',
            de: '/IT/unternehmen#Products',
            pt: '/IT/negocio#Products',
        },
    },
    {
        id: paths.business_products.cz,
        template: '/CZ/business',
        aliases: {
            cs: '/CZ/obchodnik#Products',
            sk: '/CZ/obchodnik#Products',
            en: '/CZ/business#Products',
            es: '/CZ/negocios#Products',
            it: '/CZ/business#Products',
            fr: '/CZ/entreprise#Products',
            de: '/CZ/unternehmen#Products',
            pt: '/CZ/negocio#Products',
        },
    },

    {
        id: paths.business_products.sk,
        template: '/SK/business',
        aliases: {
            cs: '/SK/obchodnik#Products',
            sk: '/SK/obchodnik#Products',
            en: '/SK/business#Products',
            es: '/SK/negocios#Products',
            it: '/SK/business#Products',
            fr: '/SK/entreprise#Products',
            de: '/SK/unternehmen#Products',
            pt: '/SK/negócio#Products',
        },
    },

    // start
    {
        id: paths.business_benefits.es,
        template: '/ES/',
        aliases: {
            cs: `/ES/#${anchors.business.benefits}`,
            sk: `/ES/#${anchors.business.benefits}`,
            en: `/ES/#${anchors.business.benefits}`,
            es: `/ES/#${anchors.business.benefits}`,
            it: `/ES/#${anchors.business.benefits}`,
            fr: `/ES/#${anchors.business.benefits}`,
            de: `/ES/#${anchors.business.benefits}`,
            pt: `/ES/#${anchors.business.benefits}`,
        },
    },
    {
        id: paths.business_benefits.it,
        template: '/IT/',
        aliases: {
            cs: `/IT/#${anchors.business.benefits}`,
            sk: `/IT/#${anchors.business.benefits}`,
            en: `/IT/#${anchors.business.benefits}`,
            es: `/IT/#${anchors.business.benefits}`,
            it: `/IT/#${anchors.business.benefits}`,
            fr: `/IT/#${anchors.business.benefits}`,
            de: `/IT/#${anchors.business.benefits}`,
            pt: `/IT/#${anchors.business.benefits}`,
        },
    },
    {
        id: paths.business_benefits.cz,
        template: '/CZ/',
        aliases: {
            cs: `/CZ/#${anchors.business.benefits}`,
            sk: `/CZ/#${anchors.business.benefits}`,
            en: `/CZ/#${anchors.business.benefits}`,
            es: `/CZ/#${anchors.business.benefits}`,
            it: `/CZ/#${anchors.business.benefits}`,
            fr: `/CZ/#${anchors.business.benefits}`,
            de: `/CZ/#${anchors.business.benefits}`,
            pt: `/CZ/#${anchors.business.benefits}`,
        },
    },
    {
        id: paths.business_benefits.sk,
        template: '/SK/',
        aliases: {
            cs: `/SK/#${anchors.business.benefits}`,
            sk: `/SK/#${anchors.business.benefits}`,
            en: `/SK/#${anchors.business.benefits}`,
            es: `/SK/#${anchors.business.benefits}`,
            it: `/SK/#${anchors.business.benefits}`,
            fr: `/SK/#${anchors.business.benefits}`,
            de: `/SK/#${anchors.business.benefits}`,
            pt: `/SK/#${anchors.business.benefits}`,
        },
    },
    // end

    {
        id: paths.business_how_it_work.es,
        template: '/ES/',
        aliases: {
            cs: `/ES/#${anchors.business.how_it_work}`,
            sk: `/ES/#${anchors.business.how_it_work}`,
            en: `/ES/#${anchors.business.how_it_work}`,
            es: `/ES/#${anchors.business.how_it_work}`,
            it: `/ES/#${anchors.business.how_it_work}`,
            fr: `/ES/#${anchors.business.how_it_work}`,
            de: `/ES/#${anchors.business.how_it_work}`,
            pt: `/ES/#${anchors.business.how_it_work}`,
        },
    },
    {
        id: paths.business_how_it_work.it,
        template: '/IT/',
        aliases: {
            cs: `/IT/#${anchors.business.how_it_work}`,
            sk: `/IT/#${anchors.business.how_it_work}`,
            en: `/IT/#${anchors.business.how_it_work}`,
            es: `/IT/#${anchors.business.how_it_work}`,
            it: `/IT/#${anchors.business.how_it_work}`,
            fr: `/IT/#${anchors.business.how_it_work}`,
            de: `/IT/#${anchors.business.how_it_work}`,
            pt: `/IT/#${anchors.business.how_it_work}`,
        },
    },
    {
        id: paths.business_how_it_work.cz,
        template: '/CZ/',
        aliases: {
            cs: `/CZ/#${anchors.business.how_it_work}`,
            sk: `/CZ/#${anchors.business.how_it_work}`,
            en: `/CZ/#${anchors.business.how_it_work}`,
            es: `/CZ/#${anchors.business.how_it_work}`,
            it: `/CZ/#${anchors.business.how_it_work}`,
            fr: `/CZ/#${anchors.business.how_it_work}`,
            de: `/CZ/#${anchors.business.how_it_work}`,
            pt: `/CZ/#${anchors.business.how_it_work}`,
        },
    },
    {
        id: paths.business_how_it_work.sk,
        template: '/SK/',
        aliases: {
            cs: `/SK/#${anchors.business.how_it_work}`,
            sk: `/SK/#${anchors.business.how_it_work}`,
            en: `/SK/#${anchors.business.how_it_work}`,
            es: `/SK/#${anchors.business.how_it_work}`,
            it: `/SK/#${anchors.business.how_it_work}`,
            fr: `/SK/#${anchors.business.how_it_work}`,
            de: `/SK/#${anchors.business.how_it_work}`,
            pt: `/SK/#${anchors.business.how_it_work}`,
        },
    },

    {
        id: paths.business_reference.es,
        template: '/ES/',
        aliases: {
            cs: `/ES/#${anchors.business.reference}`,
            sk: `/ES/#${anchors.business.reference}`,
            en: `/ES/#${anchors.business.reference}`,
            es: `/ES/#${anchors.business.reference}`,
            it: `/ES/#${anchors.business.reference}`,
            fr: `/ES/#${anchors.business.reference}`,
            de: `/ES/#${anchors.business.reference}`,
            pt: `/ES/#${anchors.business.reference}`,
        },
    },
    {
        id: paths.business_reference.it,
        template: '/IT/',
        aliases: {
            cs: `/IT/#${anchors.business.reference}`,
            sk: `/IT/#${anchors.business.reference}`,
            en: `/IT/#${anchors.business.reference}`,
            es: `/IT/#${anchors.business.reference}`,
            it: `/IT/#${anchors.business.reference}`,
            fr: `/IT/#${anchors.business.reference}`,
            de: `/IT/#${anchors.business.reference}`,
            pt: `/IT/#${anchors.business.reference}`,
        },
    },
    {
        id: paths.business_reference.cz,
        template: '/CZ/',
        aliases: {
            cs: `/CZ/#${anchors.business.reference}`,
            sk: `/CZ/#${anchors.business.reference}`,
            en: `/CZ/#${anchors.business.reference}`,
            es: `/CZ/#${anchors.business.reference}`,
            it: `/CZ/#${anchors.business.reference}`,
            fr: `/CZ/#${anchors.business.reference}`,
            de: `/CZ/#${anchors.business.reference}`,
            pt: `/CZ/#${anchors.business.reference}`,
        },
    },
    {
        id: paths.business_reference.sk,
        template: '/SK/',
        aliases: {
            cs: `/SK/#${anchors.business.reference}`,
            sk: `/SK/#${anchors.business.reference}`,
            en: `/SK/#${anchors.business.reference}`,
            es: `/SK/#${anchors.business.reference}`,
            it: `/SK/#${anchors.business.reference}`,
            fr: `/SK/#${anchors.business.reference}`,
            de: `/SK/#${anchors.business.reference}`,
            pt: `/SK/#${anchors.business.reference}`,
        },
    },
    {
        id: paths.personal.es,
        template: '/ES/personal',
        aliases: {
            cs: '/ES/uzivatel',
            sk: '/ES/uzivatel',
            en: '/ES/personal',
            es: '/ES/personal',
            it: '/ES/privati',
            fr: '/ES/personnel',
            de: '/ES/personlich',
            pt: '/ES/pessoal',
        },
    },
    {
        id: paths.personal.cz,
        template: '/CZ/personal',
        aliases: {
            cs: '/CZ/uzivatel',
            sk: '/CZ/uzivatel',
            en: '/CZ/personal',
            es: '/CZ/personal',
            it: '/CZ/privati',
            fr: '/CZ/personnel',
            de: '/CZ/personlich',
            pt: '/CZ/pessoal',
        },
    },
    {
        id: paths.personal.it,
        template: '/IT/personal',
        aliases: {
            cs: '/IT/uzivatel',
            sk: '/IT/uzivatel',
            en: '/IT/personal',
            es: '/IT/personal',
            it: '/IT/privati',
            fr: '/IT/personnel',
            de: '/IT/personlich',
            pt: '/IT/pessoal',
        },
    },
    {
        id: paths.personal.sk,
        template: '/SK/personal',
        aliases: {
            cs: '/SK/uzivatel',
            sk: '/SK/uzivatel',
            en: '/SK/personal',
            es: '/SK/personal',
            it: '/SK/privati',
            fr: '/SK/personnel',
            de: '/SK/personlich',
            pt: '/SK/pessoal',
        },
    },

    {
        id: paths.personal_reason_to_pay.es,
        template: '/ES/personal',
        aliases: {
            cs: '/ES/uzivatel#ReasonsToPay',
            sk: '/ES/uzivatel#ReasonsToPay',
            en: '/ES/personal#ReasonsToPay',
            es: '/ES/personal#ReasonsToPay',
            it: '/ES/privati#ReasonsToPay',
            fr: '/ES/personnel#ReasonsToPay',
            de: '/ES/personlich#ReasonsToPay',
            pt: '/ES/pessoal#ReasonsToPay',
        },
    },
    {
        id: paths.personal_reason_to_pay.cz,
        template: '/CZ/personal',
        aliases: {
            cs: '/CZ/uzivatel#ReasonsToPay',
            sk: '/CZ/uzivatel#ReasonsToPay',
            en: '/CZ/personal#ReasonsToPay',
            es: '/CZ/personal#ReasonsToPay',
            it: '/CZ/privati#ReasonsToPay',
            fr: '/CZ/personnel#ReasonsToPay',
            de: '/CZ/personlich#ReasonsToPay',
            pt: '/CZ/pessoal#ReasonsToPay',
        },
    },

    {
        id: paths.personal_reason_to_pay.it,
        template: '/IT/personal',
        aliases: {
            cs: '/IT/uzivatel#ReasonsToPay',
            sk: '/IT/uzivatel#ReasonsToPay',
            en: '/IT/personal#ReasonsToPay',
            es: '/IT/personal#ReasonsToPay',
            it: '/IT/privati#ReasonsToPay',
            fr: '/IT/personnel#ReasonsToPay',
            de: '/IT/personlich#ReasonsToPay',
            pt: '/IT/pessoal#ReasonsToPay',
        },
    },
    {
        id: paths.personal_reason_to_pay.sk,
        template: '/SK/personal',
        aliases: {
            cs: '/SK/uzivatel#ReasonsToPay',
            sk: '/SK/uzivatel#ReasonsToPay',
            en: '/SK/personal#ReasonsToPay',
            es: '/SK/personal#ReasonsToPay',
            it: '/SK/privati#ReasonsToPay',
            fr: '/SK/personnel#ReasonsToPay',
            de: '/SK/personlich#ReasonsToPay',
            pt: '/SK/pessoal#ReasonsToPay',
        },
    },
    {
        id: paths.personal_how_to_pay.es,
        template: '/ES/personal',
        aliases: {
            cs: '/ES/uzivatel#HowToPay',
            sk: '/ES/uzivatel#HowToPay',
            en: '/ES/personal#HowToPay',
            es: '/ES/personal#HowToPay',
            it: '/ES/privati#HowToPay',
            fr: '/ES/personnel#HowToPay',
            de: '/ES/personlich#HowToPay',
            pt: '/ES/pessoal#HowToPay',
        },
    },
    {
        id: paths.personal_how_to_pay.cz,
        template: '/CZ/personal',
        aliases: {
            cs: '/CZ/uzivatel#HowToPay',
            sk: '/CZ/uzivatel#HowToPay',
            en: '/CZ/personal#HowToPay',
            es: '/CZ/personal#HowToPay',
            it: '/CZ/privati#HowToPay',
            fr: '/CZ/personnel#HowToPay',
            de: '/CZ/personlich#HowToPay',
            pt: '/CZ/pessoal#HowToPay',
        },
    },

    {
        id: paths.personal_how_to_pay.it,
        template: '/IT/personal',
        aliases: {
            cs: '/IT/uzivatel#HowToPay',
            sk: '/IT/uzivatel#HowToPay',
            en: '/IT/personal#HowToPay',
            es: '/IT/personal#HowToPay',
            it: '/IT/privati#HowToPay',
            fr: '/IT/personnel#HowToPay',
            de: '/IT/personlich#HowToPay',
            pt: '/IT/pessoal#HowToPay',
        },
    },
    {
        id: paths.personal_how_to_pay.sk,
        template: '/SK/personal',
        aliases: {
            cs: '/SK/uzivatel#HowToPay',
            sk: '/SK/uzivatel#HowToPay',
            en: '/SK/personal#HowToPay',
            es: '/SK/personal#HowToPay',
            it: '/SK/privati#HowToPay',
            fr: '/SK/personnel#HowToPay',
            de: '/SK/personlich#HowToPay',
            pt: '/SK/pessoal#HowToPay',
        },
    },
    {
        id: paths.personal_reference.es,
        template: '/ES/personal',
        aliases: {
            cs: '/ES/uzivatel#Reference',
            sk: '/ES/uzivatel#Reference',
            en: '/ES/personal#Reference',
            es: '/ES/personal#Reference',
            it: '/ES/privati#Reference',
            fr: '/ES/personnel#Reference',
            de: '/ES/personlich#Reference',
            pt: '/ES/pessoal#Reference',
        },
    },
    {
        id: paths.personal_reference.cz,
        template: '/CZ/personal',
        aliases: {
            cs: '/CZ/uzivatel#Reference',
            sk: '/CZ/uzivatel#Reference',
            en: '/CZ/personal#Reference',
            es: '/CZ/personal#Reference',
            it: '/CZ/privati#Reference',
            fr: '/CZ/personnel#Reference',
            de: '/CZ/personlich#Reference',
            pt: '/CZ/pessoal#Reference',
        },
    },

    {
        id: paths.personal_reference.it,
        template: '/IT/personal',
        aliases: {
            cs: '/IT/uzivatel#Reference',
            sk: '/IT/uzivatel#Reference',
            en: '/IT/personal#Reference',
            es: '/IT/personal#Reference',
            it: '/IT/privati#Reference',
            fr: '/IT/personnel#Reference',
            de: '/IT/personlich#Reference',
            pt: '/IT/pessoal#Reference',
        },
    },
    {
        id: paths.personal_reference.sk,
        template: '/SK/personal',
        aliases: {
            cs: '/SK/uzivatel#Reference',
            sk: '/SK/uzivatel#Reference',
            en: '/SK/personal#Reference',
            es: '/SK/personal#Reference',
            it: '/SK/privati#Reference',
            fr: '/SK/personnel#Reference',
            de: '/SK/personlich#Reference',
            pt: '/SK/pessoal#Reference',
        },
    },
    {
        id: paths.blog.es,
        template: '/ES/blog',
        aliases: {
            template: '/ES/blog',
            cs: '/ES/blog',
            sk: '/ES/blog',
            en: '/ES/blog',
            es: '/ES/blog',
            it: '/ES/blog',
            fr: '/ES/blog',
            de: '/ES/blog',
            pt: '/ES/blog',
        },
    },

    {
        id: paths.blog.it,
        template: '/IT/blog',
        aliases: {
            cs: '/IT/blog',
            sk: '/IT/blog',
            en: '/IT/blog',
            es: '/IT/blog',
            it: '/IT/blog',
            fr: '/IT/blog',
            de: '/IT/blog',
            pt: '/IT/blog',
        },
    },
    {
        id: paths.blog.cz,
        template: '/CZ/blog',
        aliases: {
            cs: '/CZ/blog',
            sk: '/CZ/blog',
            en: '/CZ/blog',
            es: '/CZ/blog',
            it: '/CZ/blog',
            fr: '/CZ/blog',
            de: '/CZ/blog',
            pt: '/CZ/blog',
        },
    },
    {
        id: paths.blog.sk,
        template: '/SK/blog',
        aliases: {
            cs: '/SK/blog',
            sk: '/SK/blog',
            en: '/SK/blog',
            es: '/SK/blog',
            it: '/SK/blog',
            fr: '/SK/blog',
            de: '/SK/blog',
            pt: '/SK/blog',
        },
    },
    {
        id: paths.faq,
        template: '/faq/merchant',
        aliases: {
            cs: '/faq',
            sk: '/faq',
            en: '/faq',
            es: '/faq',
            it: '/faq',
            fr: '/faq',
            de: '/faq',
            pt: '/faq',
        },
    },
    {
        // todo: we should add country prefix here as well I think
        id: paths.business_payment_link,
        template: '/payment-link',
        aliases: {
            cs: '/platebni-odkaz',
            sk: '/platobny-odkaz',
            en: '/payment-link',
            es: '/enlace-de-pago',
            it: '/link-di-pagamento',
            fr: '/lien-paiement',
            de: '/zahlungs-link',
            pt: '/pay-link',
        },
    },
    {
        id: paths.pos,
        template: '/CZ/ocellot',
        aliases: {
            cs: '/CZ/pokladna',
            sk: '/CZ/pokladna',
            en: '/CZ/pos',
            es: '/CZ/pos',
            it: '/CZ/pos',
            fr: '/CZ/pos',
            de: '/CZ/pos',
            pt: '/CZ/pos',
        },
    },
    {
        id: paths.pos_sign_up.cz,
        template: '/CZ/ocellot/sign-up',
        aliases: {
            cs: '/CZ/pokladna/registrace',
            sk: '/CZ/pokladna/registracia',
            en: '/CZ/pos/sign-up',
            es: '/CZ/pos/sign-up',
            it: '/CZ/pos/sign-up',
            fr: '/CZ/pos/sign-up',
            de: '/CZ/pos/sign-up',
            pt: '/CZ/pos/sign-up',
        },
    },
    {
        id: paths.faq_customer,
        template: '/faq/customer',
        aliases: {
            cs: '/faq/uzivatel',
            sk: '/faq/uzivatel',
            en: '/faq/customer',
            es: '/faq/personal',
            it: '/faq/privati',
            fr: '/faq/personnel',
            de: '/faq/personlich',
            pt: '/faq/pessoal',
        },
    },

    {
        id: paths.contact,
        template: '/contact',
        aliases: {
            cs: '/kontakt',
            sk: '/kontakt',
            en: '/contact',
            es: '/contacto',
            it: '/contatti',
            fr: '/contact',
            de: '/kontakt',
            pt: '/contacto',
        },
    },

    {
        id: paths.license,
        template: '/license',
        aliases: {
            cs: '/licence',
            sk: '/licencia',
            en: '/license',
            es: '/licencia',
            it: '/licenza',
            fr: '/licença',
            de: '/lizenz',
            pt: '/licença',
        },
    },

    {
        id: paths.sing_in,
        template: '/sing-in',
        aliases: {
            cs: '/prihlaseni',
            sk: '/prihlaseni',
            en: '/sing-in',
            es: '/iniciar-sesion',
            it: '/iscriviti',
            fr: '/inscrire',
            de: '/anmeldung',
            pt: '/ingresso',
        },
    },
    {
        id: paths.about,
        template: '/about',
        aliases: {
            cs: '/o-nas',
            sk: '/o-nas',
            en: '/about',
            es: '/conocenos',
            it: '/su-di',
            fr: '/propos',
            de: '/uber',
            pt: '/sobre',
        },
    },

    {
        id: paths.register,
        template: null,
        aliases: {
            cs: 'https://business.trisbee.com/cs/',
            sk: 'https://business.trisbee.com/sk/',
            en: 'https://business.trisbee.com/en/',
            es: 'https://business.trisbee.com/es/',
            it: 'https://business.trisbee.com/it/',
            fr: 'https://business.trisbee.com/en/',
            de: 'https://business.trisbee.com/en/',
            pt: 'https://business.trisbee.com/en/',
        },
        noIndex: true,
    },

    {
        id: paths.terms.es,
        template: '/ES/terms',
        aliases: {
            cs: '/ES/podminky',
            sk: '/ES/podminky',
            en: '/ES/terms',
            es: '/ES/terminos',
            it: '/ES/termini',
            fr: '/ES/termes',
            de: '/ES/bedingungen',
            pt: '/ES/termos',
        },
        noIndex: true,
    },
    {
        id: paths.terms_archive.es,
        template: '/ES/terms/archive',
        aliases: {
            cs: '/ES/podminky/archiv',
            sk: '/ES/podminky/archiv',
            en: '/ES/terms/archive',
            es: '/ES/terminos/archivo',
            it: '/ES/termini/archivio',
            fr: '/ES/termes/archives',
            de: '/ES/bedingungen/archiv',
            pt: '/ES/termos/arquivo',
        },
        noIndex: true,
    },
    {
        id: paths.terms.cz,
        template: '/CZ/terms',
        aliases: {
            cs: '/CZ/podminky',
            sk: '/CZ/podminky',
            en: '/CZ/terms',
            es: '/CZ/terminos',
            it: '/CZ/termini',
            fr: '/CZ/termes',
            de: '/CZ/bedingungen',
            pt: '/CZ/termos',
        },
        noIndex: true,
    },
    {
        id: paths.terms_archive.cz,
        template: '/CZ/terms/archive',
        aliases: {
            cs: '/CZ/podminky/archiv',
            sk: '/CZ/podminky/archiv',
            en: '/CZ/terms/archive',
            es: '/CZ/terminos/archivo',
            it: '/CZ/termini/archivio',
            fr: '/CZ/termes/archives',
            de: '/CZ/bedingungen/archiv',
            pt: '/CZ/termos/arquivo',
        },
        noIndex: true,
    },
    {
        id: paths.terms.it,
        template: '/IT/terms',
        aliases: {
            cs: '/IT/podminky',
            sk: '/IT/podminky',
            en: '/IT/terms',
            es: '/IT/terminos',
            it: '/IT/termini',
            fr: '/IT/termes',
            de: '/IT/bedingungen',
            pt: '/IT/termos',
        },
        noIndex: true,
    },
    {
        id: paths.terms_archive.it,
        template: '/IT/terms/archive',
        aliases: {
            cs: '/IT/podminky/archiv',
            sk: '/IT/podminky/archiv',
            en: '/IT/terms/archive',
            es: '/IT/terminos/archivo',
            it: '/IT/termini/archivio',
            fr: '/IT/termes/archives',
            de: '/IT/bedingungen/archiv',
            pt: '/IT/termos/arquivo',
        },
        noIndex: true,
    },
    {
        id: paths.terms.sk,
        template: '/SK/terms',
        aliases: {
            cs: '/SK/podminky',
            sk: '/SK/podminky',
            en: '/SK/terms',
            es: '/SK/terminos',
            it: '/SK/termini',
            fr: '/Sk/termes',
            de: '/Sk/bedingungen',
            pt: '/Sk/termos',
        },
        noIndex: true,
    },
    {
        id: paths.terms_archive.sk,
        template: '/SK/terms/archive',
        aliases: {
            cs: '/SK/podminky/archiv',
            sk: '/SK/podminky/archiv',
            en: '/SK/terms/archive',
            es: '/SK/terminos/archivo',
            it: '/SK/termini/archivio',
            fr: '/SK/termes/archives',
            de: '/SK/bedingungen/archiv',
            pt: '/SK/termos/arquivo',
        },
        noIndex: true,
    },

    {
        id: paths.externalPayApp,
        template: null,
        aliases: {
            cs: 'https://pay.trisbee.com/?lang=cs',
            sk: 'https://pay.trisbee.com/?lang=sk',
            en: 'https://pay.trisbee.com/?lang=en',
            es: 'https://pay.trisbee.com/?lang=es',
            it: 'https://pay.trisbee.com/?lang=it',
            fr: 'https://pay.trisbee.com/?lang=en',
            de: 'https://pay.trisbee.com/?lang=en',
            pt: 'https://pay.trisbee.com/?lang=en',
        },
    },
    {
        id: paths.externalPayAppSavedCards,
        template: null,
        aliases: {
            cs: 'https://pay.trisbee.com/profile/cards?lang=cs',
            sk: 'https://pay.trisbee.com/profile/cards?lang=sk',
            en: 'https://pay.trisbee.com/profile/cards?lang=en',
            es: 'https://pay.trisbee.com/profile/cards?lang=es',
            it: 'https://pay.trisbee.com/profile/cards?lang=it',
            fr: 'https://pay.trisbee.com/profile/cards?lang=en',
            de: 'https://pay.trisbee.com/profile/cards?lang=en',
            pt: 'https://pay.trisbee.com/profile/cards?lang=en',
        },
    },
    {
        id: paths.externalBusinessWebApp,
        template: null,
        aliases: {
            cs: 'https://business.trisbee.com/cs/',
            sk: 'https://business.trisbee.com/sk/',
            en: 'https://business.trisbee.com/en/',
            es: 'https://business.trisbee.com/es/',
            it: 'https://business.trisbee.com/it/',
            fr: 'https://business.trisbee.com/en/', // todo: change it to FR, when it's available
            de: 'https://business.trisbee.com/en/', // todo: change it to DE, when it's available
            pt: 'https://business.trisbee.com/en/', // todo: change it to PT, when it's available
        },
    },
    {
        id: paths.privacy,
        template: null,
        aliases: {
            cs:
                'https://github.com/trisbee/policies/blob/master/english/terms_and_conditions.md', //todo: maybe bad routs, check and resolve
            sk:
                'https://github.com/trisbee/policies/blob/master/english/terms_and_conditions.md',
            en:
                'https://github.com/trisbee/policies/blob/master/czech/obchodni_podminky.md',
            es:
                'https://github.com/trisbee/policies/blob/master/czech/terminos.md',
            it:
                'https://github.com/trisbee/policies/blob/master/czech/termini.md',
            fr:
                'https://github.com/trisbee/policies/blob/master/english/conditions.md',
            de:
                'https://github.com/trisbee/policies/blob/master/english/bedingungen_und_konditionen.md',
            pt:
                'https://github.com/trisbee/policies/blob/master/english/termos_e_condicoes.md',
        },
        noIndex: true,
    },
    {
        id: paths.facebook,
        template: null,
        aliases: {
            cs: 'https://www.facebook.com/trisbeecom/',
            sk: 'https://www.facebook.com/trisbeecom/',
            en: 'https://www.facebook.com/trisbeecom/',
            es: 'https://www.facebook.com/trisbee.es/',
            it: 'https://www.facebook.com/trisbee.it/',
            fr: 'https://www.facebook.com/trisbeecom/',
            de: 'https://www.facebook.com/trisbeecom/',
            pt: 'https://www.facebook.com/trisbeecom/',
        },
        noIndex: true,
    },
    {
        id: paths.instagram,
        template: null,
        aliases: {
            cs: 'https://www.instagram.com/trisbeecom',
            sk: 'https://www.instagram.com/trisbeecom',
            en: 'https://www.instagram.com/trisbeecom',
            es: 'https://www.instagram.com/trisbee.es',
            it: 'https://www.instagram.com/trisbee.it',
            fr: 'https://www.instagram.com/trisbeecom',
            de: 'https://www.instagram.com/trisbeecom',
            pt: 'https://www.instagram.com/trisbeecom',
        },
        noIndex: true,
    },
    {
        id: paths.twitter,
        template: null,
        aliases: {
            cs: 'https://twitter.com/trisbeecom',
            sk: 'https://twitter.com/trisbeecom',
            en: 'https://twitter.com/trisbeecom',
            es: 'https://twitter.com/trisbeecom',
            it: 'https://twitter.com/trisbeecom',
            fr: 'https://twitter.com/trisbeecom',
            de: 'https://twitter.com/trisbeecom',
            pt: 'https://twitter.com/trisbeecom',
        },
        noIndex: true,
    },
    {
        id: paths.linkedin,
        template: null,
        aliases: {
            cs: 'https://www.linkedin.com/company/trisbee/',
            sk: 'https://www.linkedin.com/company/trisbee/',
            en: 'https://www.linkedin.com/company/trisbee/',
            es: 'https://www.linkedin.com/company/trisbee/',
            it: 'https://www.linkedin.com/company/trisbee/',
            fr: 'https://www.linkedin.com/company/trisbee/',
            de: 'https://www.linkedin.com/company/trisbee/',
            pt: 'https://www.linkedin.com/company/trisbee/',
        },
        noIndex: true,
    },
    {
        // todo: obsolute??
        id: paths.privacy,
        template: null,
        aliases: {
            cs:
                'https://github.com/trisbee/policies/blob/master/czech/ochrana_soukromi.md',
            sk:
                'https://github.com/trisbee/policies/blob/master/czech/ochrana_soukromi.md',
            en:
                'https://github.com/trisbee/policies/blob/master/english/privacy.md',
            es:
                'https://github.com/trisbee/policies/blob/master/english/privacy.md',
            it:
                'https://github.com/trisbee/policies/blob/master/english/privacy.md',
            fr:
                'https://github.com/trisbee/policies/blob/master/english/privacy.md',
            de:
                'https://github.com/trisbee/policies/blob/master/english/privacy.md',
            pt:
                'https://github.com/trisbee/policies/blob/master/english/privacy.md',
        },
        noIndex: true,
    },
    {
        id: paths.terms_transformation.cz,
        template: '/CZ/terms/transformation',
        aliases: {
            cs: '/CZ/podminky/oznameni-premeny',
            sk: '/CZ/podminky/oznameni-premeny',
            en: '/CZ/podminky/oznameni-premeny',
            es: '/CZ/podminky/oznameni-premeny',
            it: '/CZ/podminky/oznameni-premeny',
            fr: '/CZ/podminky/oznameni-premeny',
            de: '/CZ/podminky/oznameni-premeny',
            pt: '/CZ/podminky/oznameni-premeny',
        }
    },
    {
        id: paths.terms_transformation.sk,
        template: '/CZ/terms/transformation',
        aliases: {
            cs: '/CZ/podminky/oznameni-premeny',
            sk: '/CZ/podminky/oznameni-premeny',
            en: '/CZ/podminky/oznameni-premeny',
            es: '/CZ/podminky/oznameni-premeny',
            it: '/CZ/podminky/oznameni-premeny',
            fr: '/CZ/podminky/oznameni-premeny',
            de: '/CZ/podminky/oznameni-premeny',
            pt: '/CZ/podminky/oznameni-premeny',
        }
    },
    {
        id: paths.terms_transformation.es,
        template: '/CZ/terms/transformation',
        aliases: {
            cs: '/CZ/podminky/oznameni-premeny',
            sk: '/CZ/podminky/oznameni-premeny',
            en: '/CZ/podminky/oznameni-premeny',
            es: '/CZ/podminky/oznameni-premeny',
            it: '/CZ/podminky/oznameni-premeny',
            fr: '/CZ/podminky/oznameni-premeny',
            de: '/CZ/podminky/oznameni-premeny',
            pt: '/CZ/podminky/oznameni-premeny',
        }
    },
    {
        id: paths.terms_transformation.it,
        template: '/CZ/terms/transformation',
        aliases: {
            cs: '/CZ/podminky/oznameni-premeny',
            sk: '/CZ/podminky/oznameni-premeny',
            en: '/CZ/podminky/oznameni-premeny',
            es: '/CZ/podminky/oznameni-premeny',
            it: '/CZ/podminky/oznameni-premeny',
            fr: '/CZ/podminky/oznameni-premeny',
            de: '/CZ/podminky/oznameni-premeny',
            pt: '/CZ/podminky/oznameni-premeny',
        }
    },
    {
        id: paths.terms_transformation.fr,
        template: '/CZ/terms/transformation',
        aliases: {
            cs: '/CZ/podminky/oznameni-premeny',
            sk: '/CZ/podminky/oznameni-premeny',
            en: '/CZ/podminky/oznameni-premeny',
            es: '/CZ/podminky/oznameni-premeny',
            it: '/CZ/podminky/oznameni-premeny',
            fr: '/CZ/podminky/oznameni-premeny',
            de: '/CZ/podminky/oznameni-premeny',
            pt: '/CZ/podminky/oznameni-premeny',
        }
    },
    {
        id: paths.terms_transformation.de,
        template: '/CZ/terms/transformation',
        aliases: {
            cs: '/CZ/podminky/oznameni-premeny',
            sk: '/CZ/podminky/oznameni-premeny',
            en: '/CZ/podminky/oznameni-premeny',
            es: '/CZ/podminky/oznameni-premeny',
            it: '/CZ/podminky/oznameni-premeny',
            fr: '/CZ/podminky/oznameni-premeny',
            de: '/CZ/podminky/oznameni-premeny',
            pt: '/CZ/podminky/oznameni-premeny',
        }
    },
    {
        id: paths.terms_transformation.pt,
        template: '/CZ/terms/transformation',
        aliases: {
            cs: '/CZ/podminky/oznameni-premeny',
            sk: '/CZ/podminky/oznameni-premeny',
            en: '/CZ/podminky/oznameni-premeny',
            es: '/CZ/podminky/oznameni-premeny',
            it: '/CZ/podminky/oznameni-premeny',
            fr: '/CZ/podminky/oznameni-premeny',
            de: '/CZ/podminky/oznameni-premeny',
            pt: '/CZ/podminky/oznameni-premeny',
        }
    },
    {
        id: paths.terms_users.es,
        template: '/ES/terms/users',
        aliases: {
            cs: '/ES/podminky/uzivatel',
            sk: '/ES/podminky/uzivatel',
            en: '/ES/terms/users',
            es: '/ES/terminos/personal',
            it: '/ES/termini/utenti',
            fr: '/ES/termes/personnel',
            de: '/ES/bedingungen/personlich',
            pt: '/ES/termos/pessoal',
        },
    },
    {
        id: paths.terms_users.pe,
        template: '/PE/terms/users',
        aliases: {
            cs: '/PE/terms/users',
            sk: '/PE/terms/users',
            en: '/PE/terms/users',
            es: '/PE/terms/users',
            it: '/PE/terms/users',
            fr: '/PE/terms/users',
            de: '/PE/terms/users',
            pt: '/PE/terms/users',
        },
    },
    {
        id: paths.terms_users.cz,
        template: '/CZ/terms/users',
        aliases: {
            cs: '/CZ/podminky/uzivatel',
            sk: '/CZ/podminky/uzivatel',
            en: '/CZ/terms/users',
            es: '/CZ/terminos/personal',
            it: '/CZ/termini/utenti',
            fr: '/CZ/termes/personnel',
            de: '/CZ/bedingungen/personlich',
            pt: '/CZ/termos/pessoal',
        },
    },
    {
        id: paths.terms_users.it,
        template: '/IT/terms/users',
        aliases: {
            cs: '/IT/podminky/uzivatel',
            sk: '/IT/podminky/uzivatel',
            en: '/IT/terms/users',
            es: '/IT/terminos/personal',
            it: '/IT/termini/utenti',
            fr: '/IT/termes/personnel',
            de: '/IT/bedingungen/personlich',
            pt: '/IT/termos/pessoal',
        },
    },
    {
        id: paths.terms_users.sk,
        template: '/SK/terms/users',
        aliases: {
            cs: '/SK/podminky/uzivatel',
            sk: '/SK/podminky/uzivatel',
            en: '/SK/terms/users',
            es: '/SK/terminos/personal',
            it: '/SK/termini/utenti',
            fr: '/SK/termes/personnel',
            de: '/SK/bedingungen/personlich',
            pt: '/SK/termos/pessoal',
        },
    },

    {
        id: paths.terms_merchants.es,
        template: '/ES/terms/merchants',
        aliases: {
            cs: '/ES/podminky/obchodnik',
            sk: '/ES/podminky/obchodnik',
            en: '/ES/terms/merchants',
            es: '/ES/terminos/negocios',
            it: '/ES/termini/commercianti',
            fr: '/ES/termes/commercants',
            de: '/ES/bedingungen/kaufleute',
            pt: '/ES/termos/negociantes',
        },
    },
    {
        id: paths.terms_merchants.pe,
        template: '/PE/terms/merchants',
        aliases: {
            cs: '/PE/terms/merchants',
            sk: '/PE/terms/merchants',
            en: '/PE/terms/merchants',
            es: '/PE/terms/merchants',
            it: '/PE/terms/merchants',
            fr: '/PE/terms/merchants',
            de: '/PE/terms/merchants',
            pt: '/PE/terms/merchants',
        },
    },
    {
        id: paths.terms_merchants.cz,
        template: '/CZ/terms/merchants',
        aliases: {
            cs: '/CZ/podminky/obchodnik',
            sk: '/CZ/podminky/obchodnik',
            en: '/CZ/terms/merchants',
            es: '/CZ/terminos/negocios',
            it: '/CZ/termini/commercianti',
            fr: '/CZ/termes/commercants',
            de: '/CZ/bedingungen/kaufleute',
            pt: '/CZ/termos/negociantes',
        },
    },
    {
        id: paths.terms_merchants.it,
        template: '/IT/terms/merchants',
        aliases: {
            cs: '/IT/podminky/obchodnik',
            sk: '/IT/podminky/obchodnik',
            en: '/IT/terms/merchants',
            es: '/IT/terminos/negocios',
            it: '/IT/termini/commercianti',
            fr: '/IT/termes/commercants',
            de: '/IT/bedingungen/kaufleute',
            pt: '/IT/termos/negociantes',
        },
    },
    {
        id: paths.terms_merchants.sk,
        template: '/SK/terms/merchants',
        aliases: {
            cs: '/SK/podminky/obchodnik',
            sk: '/SK/podminky/obchodnik',
            en: '/SK/terms/merchants',
            es: '/SK/terminos/negocios',
            it: '/SK/termini/commercianti',
            fr: '/SK/termes/commercants',
            de: '/SK/bedingungen/kaufleute',
            pt: '/SK/termos/negociantes',
        },
    },

    {
        id: paths.terms_gdpr.es,
        template: '/ES/terms/gdpr',
        aliases: {
            cs: '/ES/podminky/gdpr',
            sk: '/ES/podminky/gdpr',
            en: '/ES/terms/gdpr',
            es: '/ES/terminos/gdpr',
            it: '/ES/termini/gdpr',
            fr: '/ES/termes/gdpr',
            de: '/ES/bedingungen/gdpr',
            pt: '/ES/termos/gdpr',
        },
    },
    {
        id: paths.terms_gdpr.cz,
        template: '/CZ/terms/gdpr',
        aliases: {
            cs: '/CZ/podminky/gdpr',
            sk: '/CZ/podminky/gdpr',
            en: '/CZ/terms/gdpr',
            es: '/CZ/terminos/gdpr',
            it: '/CZ/termini/gdpr',
            fr: '/CZ/termes/gdpr',
            de: '/CZ/bedingungen/gdpr',
            pt: '/CZ/termos/gdpr',
        },
    },
    {
        id: paths.terms_gdpr.it,
        template: '/IT/terms/gdpr',
        aliases: {
            cs: '/IT/podminky/gdpr',
            sk: '/IT/podminky/gdpr',
            en: '/IT/terms/gdpr',
            es: '/IT/terminos/gdpr',
            it: '/IT/termini/gdpr',
            fr: '/IT/termes/gdpr',
            de: '/IT/bedingungen/gdpr',
            pt: '/IT/termos/gdpr',
        },
    },
    {
        id: paths.terms_gdpr.sk,
        template: '/SK/terms/gdpr',
        aliases: {
            cs: '/SK/podminky/gdpr',
            sk: '/SK/podminky/gdpr',
            en: '/SK/terms/gdpr',
            es: '/SK/terminos/gdpr',
            it: '/SK/termini/gdpr',
            fr: '/SK/termes/gdpr',
            de: '/SK/bedingungen/gdpr',
            pt: '/SK/termos/gdpr',
        },
    },
    {
        id: paths.terms_gdpr.pe,
        template: '/PE/terms/gdpr',
        aliases: {
            cs: '/PE/terms/gdpr',
            sk: '/PE/terms/gdpr',
            en: '/PE/terms/gdpr',
            es: '/PE/terms/gdpr',
            it: '/PE/terms/gdpr',
            fr: '/PE/terms/gdpr',
            de: '/PE/terms/gdpr',
            pt: '/PE/terms/gdpr',
        },
    },

    {
        id: paths.terms_cookies.es,
        template: '/ES/terms/cookies',
        aliases: {
            cs: '/ES/podminky/cookies',
            sk: '/ES/podminky/cookies',
            en: '/ES/terms/cookies',
            es: '/ES/terminos/cookies',
            it: '/ES/termini/cookies',
            fr: '/ES/termes/cookies',
            de: '/ES/bedingungen/cookies',
            pt: '/ES/termos/cookies',
        },
    },
    {
        id: paths.terms_cookies.pe,
        template: '/PE/terms/cookies',
        aliases: {
            cs: '/PE/terms/cookies',
            sk: '/PE/terms/cookies',
            en: '/PE/terms/cookies',
            es: '/PE/terms/cookies',
            it: '/PE/terms/cookies',
            fr: '/PE/terms/cookies',
            de: '/PE/terms/cookies',
            pt: '/PE/terms/cookies',
        },
    },
    {
        id: paths.terms_cookies.cz,
        template: '/CZ/terms/cookies',
        aliases: {
            cs: '/CZ/podminky/cookies',
            sk: '/CZ/podminky/cookies',
            en: '/CZ/terms/cookies',
            es: '/CZ/terminos/cookies',
            it: '/CZ/termini/cookies',
            fr: '/CZ/termes/cookies',
            de: '/CZ/bedingungen/cookies',
            pt: '/CZ/termos/cookies',
        },
    },
    {
        id: paths.terms_cookies.it,
        template: '/IT/terms/cookies',
        aliases: {
            cs: '/IT/podminky/cookies',
            sk: '/IT/podminky/cookies',
            en: '/IT/terms/cookies',
            es: '/IT/terminos/cookies',
            it: '/IT/termini/cookies',
            fr: '/IT/termes/cookies',
            de: '/IT/bedingungen/cookies',
            pt: '/IT/termos/cookies',
        },
    },
    {
        id: paths.terms_cookies.sk,
        template: '/SK/terms/cookies',
        aliases: {
            cs: '/SK/podminky/cookies',
            sk: '/SK/podminky/cookies',
            en: '/SK/terms/cookies',
            es: '/SK/terminos/cookies',
            it: '/SK/termini/cookies',
            fr: '/SK/termes/cookies',
            de: '/SK/bedingungen/cookies',
            pt: '/SK/termos/cookies',
        },
    },
    {
        // todo: fix this for peru
        id: paths.terms_cashback,
        template: null,
        aliases: {
            cs:
                'https://storage.googleapis.com/production_web/web/company-site/terms/cashback_cs.pdf',
            sk:
                'https://storage.googleapis.com/production_web/web/company-site/terms/cashback_cs.pdf',
            en:
                'https://storage.googleapis.com/production_web/web/company-site/terms/cashback_en.pdf',
            es:
                'https://storage.googleapis.com/production_web/web/company-site/terms/cashback_en.pdf',
            it:
                'https://storage.googleapis.com/production_web/web/company-site/terms/cashback_en.pdf',
            fr:
                'https://storage.googleapis.com/production_web/web/company-site/terms/cashback_en.pdf',
            de:
                'https://storage.googleapis.com/production_web/web/company-site/terms/cashback_en.pdf',
            pt:
                'https://storage.googleapis.com/production_web/web/company-site/terms/cashback_en.pdf',
        },
    },
];

module.exports.paths = paths;
module.exports.routes = routes;
module.exports.anchors = anchors;
module.exports.queryParams = queryParams;
